import '../styles/pappas.scss';

import { createApp } from 'vue';

import { Collapse, ScrollSpy } from 'bootstrap';

import InlineSvg from 'vue-inline-svg';

// import PappasFinder from '../../../pappas-finder/src/components/Finder.vue';
// import PappasLeadsForm from '../../../pappas-leads-form/src/index.js';
// import PappasReservationForm from '../../../pappas-reservation-form/src/js/components/ReservationForm.vue';
import PappasFinder from '@pappas/finder';
import PappasReservationForm from '@pappas/reservation-form';
import PappasLeadsForm from '@pappas/leads-form';

import ControlsStickyHeader from './mixins/controls-sticky-header'; 

import CareersPerksCollapse from './components/Careers/PerksCollapse.vue';
import CareersResults from './components/Careers/Results.vue';
import CareersResultItem from './components/Careers/ResultItem.vue';
import CareersSearchBanner from './components/Careers/SearchBanner.vue';
import CareersSearchForm from './components/Careers/SearchForm.vue';
import Carousel from './components/Carousel.vue';
import ContactFormHeader from './components/Contact/FormHeader.vue';
import FormInput from './components/FormInput.vue';
import HomeSocialImpactCarousel from './components/Home/SocialImpactCarousel.vue';
import NewsletterForm from './components/NewsletterForm.vue';
import SocialImpactCarousel from './components/SocialImpact/Carousel.vue';
import TestimonialsCarouselNav from './components/Careers/TestimonialsCarouselNav.vue';


const app = createApp({
    mixins: [ ControlsStickyHeader ],

    components: {
        CareersPerksCollapse,
        CareersResults,
        CareersResultItem,
        CareersSearchBanner,
        CareersSearchForm,
        Carousel,
        ContactFormHeader,
        FormInput,
        HomeSocialImpactCarousel,
        NewsletterForm,
        SocialImpactCarousel,
        TestimonialsCarouselNav,
    },

    data() {
        return {
            pappasFinderClasses: {
                button: 'btn btn-primary',
                viewport: 'pb-0',
                searchbar: {
                    input: 'form-control',
                },
                result: {
                    button: 'btn btn-sm btn-primary text-nowrap',
                },
                filters: {
                    button: 'btn-sm'
                },
                results: {
                }
            },

            $hamburger: null,
            $nav: null,
            navActive: false,
        }
    },

    mounted() {
        this.$hamburger = document.querySelector('.hamburger');
        this.$nav = document.querySelector('.site-header-nav');
    },

    methods: {
        toggleNav() {
            this.navActive = !this.navActive;
            document.querySelector('body').classList.toggle('overflow-hidden');
        }
    }
})


app.component('inline-svg', InlineSvg);

app.use(PappasLeadsForm);
app.use(PappasFinder);
app.use(PappasReservationForm);

app.mount('#app');
